
import { Component, Prop } from 'vue-property-decorator';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';

@Component({
  components: { VFormBuilder },
})
export default class ImageForm extends mixins(StackedForm) {
  @Prop({ type: Boolean, default: false }) public multiple!: boolean;

  get items() {
    return [{ name: 'image', type: InputType.File, label: 'coupon.form.image', default: null, accept: 'image/*' }];
  }

  public getData() {
    const data: any = { ...this.$refs.form.form };
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    if (data.image === null) {
      delete data.image;
    }

    if (this.initialValues) {
      // @ts-ignore
      if (
        // @ts-ignore
        this.initialValues.image &&
        // @ts-ignore
        this.initialValues.image.thumb &&
        // @ts-ignore
        this.initialValues.image.thumb.length > 0 &&
        filesToRemove.indexOf('assets') > -1
      ) {
        data.image = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
    }

    if (this.multiple && data.image) {
      return { file: data.image };
    }

    return { ...data };
  }
}
