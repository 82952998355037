
import { Component, Prop } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import GeneralForm from '@/components/promo/form/GeneralForm.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import Editable from '@/mixins/Editable';
import Notification from '@/mixins/Notification';
import { namespace } from 'vuex-class';
import { PromoCode } from '@/interfaces/models/PromoCode';
import { Venue } from '@/interfaces/models/Venue';
import { Article as ArticleModel } from '@/interfaces/models/Article';
import EditPromoCodeLoader from '@/components/promo/EditPromoCodeLoader.vue';
import { AxiosResponse } from 'axios';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import SimpleGeneralForm from '@/components/promo/form/SimpleGeneralForm.vue';
import { User } from '@/interfaces/models/User';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { Permission } from '@/enums/Permission';
import { CustomerGroup } from '@/enums/CustomerGroup';
import AvailabilityForm from '@/components/promo/form/AvailabilityForm.vue';
import { PromoCodeTeedeli } from '@/interfaces/models/PromoCodeTeedeli';
import { PiggyReward } from '@/interfaces/models/PiggyReward';
import ImageForm from '@/components/promo/form/ImageForm.vue';
import AvailableHourForm from '@/components/promo/form/AvailableHourForm.vue';
import { formDataAppendObject } from '@/util/helper';

const promo = namespace('promo');
const app = namespace('app');
const auth = namespace('auth');
const venue = namespace('venue');
const label = namespace('venueLabel');
const reward = namespace('reward');

@Component({
  components: {
    SimpleGeneralForm,
    EditPromoCodeLoader,
    GeneralForm,
    VWrapper,
    AvailabilityForm,
    ImageForm,
    AvailableHourForm,
  },
})
export default class EditPromoCode extends mixins(Editable, Notification, StackedForm) {
  @Prop({ type: Boolean, default: false }) public multiple!: boolean;
  @Prop({ type: Boolean, default: false }) public teedeli!: boolean;

  public $refs!: {
    general: InstanceType<typeof GeneralForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
    image: InstanceType<typeof ImageForm> & { getData: () => any };
    availability: InstanceType<typeof AvailabilityForm> & { getData: () => any };
    availableHours: InstanceType<typeof AvailableHourForm> & { getData: () => any };
  };

  @app.State('venues') public venues!: Venue[];
  @auth.State('user') public authUser!: User;
  @venue.State('active') public venue!: Venue;
  @promo.State('active') public active!: PromoCode;
  @label.State('items') public labels!: VenueLabel[];
  @label.Action('fetch') public getVenueLabels!: any;

  @promo.Action('uploadGiftCards') public uploadGiftCards!: any;
  @promo.Action('store') public store!: any;
  @promo.Action('storeTeedeliCode') public storeTeedeliCode!: any;
  @promo.Action('generate') public generateCodes!: any;
  @promo.Action('show') public show!: any;
  @promo.Action('update') public update!: any;
  @promo.Action('uploadImage') public uploadImage!: any;

  @reward.Action('getByCustomerGroup') public getRewards!: any;
  @reward.Action('resetRewards') public resetRewards!: any;
  @reward.Action('setFilter') public setRewardsFilter!: any;
  @reward.State('items') public rewards!: PiggyReward[];

  public venuesByGroupAndCountry: Venue[] = [];
  public articles: Array<Partial<ArticleModel>> = [];

  get customerGroup() {
    if (this.authUser.customerGroup && this.authUser.customerGroup !== CustomerGroup.Default) {
      return this.authUser.customerGroup;
    }
    if (this.authUser.linkedCustomerGroup && this.authUser.linkedCustomerGroup !== CustomerGroup.Default) {
      return this.authUser.linkedCustomerGroup;
    }

    return null;
  }

  get linkedCustomerGroup() {
    if (this.authUser.linkedCustomerGroup && this.authUser.linkedCustomerGroup !== CustomerGroup.Default) {
      return this.authUser.linkedCustomerGroup;
    }

    if (this.authUser.customerGroup && this.authUser.customerGroup !== CustomerGroup.Default) {
      return this.authUser.customerGroup;
    }

    return null;
  }

  get promoCode() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  get title() {
    return this.editing ? 'promo.edit' : 'promo.create';
  }

  get venuesSorted(): Venue[] {
    return this.venuesByGroupAndCountry;
  }

  get filteredRewards() {
    return this.rewards.map((reward: PiggyReward) => {
      reward.disable = false;
      return reward;
    });
  }

  public async mounted() {
    this.$startLoading('promo');
    await this.resetRewards();

    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels();
    }

    if (this.editing) {
      await this.show({ id: this.id });

      if (this.promoCode) {
        await this.getRewardsData(this.promoCode.venues, this.promoCode.customerGroup as CustomerGroup);
      }
    }

    if (this.editing && !this.active) {
      this.$router.push({ name: 'promo.index' });
    }

    this.$stopLoading('promo');
    this.venuesByGroupAndCountry = this.venues;
  }

  public async onCountriesOrCustomerGroupChange(data: {
    countries: string[];
    customerGroup: CustomerGroup;
    linkedCustomerGroup: CustomerGroup;
  }) {
    const customerGroup: CustomerGroup = data.customerGroup
      ? data.customerGroup
      : (this.customerGroup as CustomerGroup);

    if (data.countries?.length) {
      this.venuesByGroupAndCountry = this.venues.filter((venue: Venue) => {
        if (data.countries.find((country: string) => venue.country === country)) {
          return venue;
        }
      });
    } else {
      this.venuesByGroupAndCountry = this.venues;
    }

    if (customerGroup) {
      this.venuesByGroupAndCountry = this.venuesByGroupAndCountry.filter(
        (venue: Venue) => venue.customerGroup === customerGroup,
      );
    }
  }

  public async onVenuesChange(data: { venues: string[]; countries: string[]; customerGroup: CustomerGroup }) {
    const foodcardApi: FoodcardApiService = new FoodcardApiService();
    let articles: Array<Partial<ArticleModel>> = [];

    await this.getRewardsData(data.venues, data.customerGroup);

    this.$startLoading('article');
    for (const id of data.venues) {
      const articleRes: AxiosResponse<Array<Partial<ArticleModel>>> = await foodcardApi.getArticleNames({
        venue: id,
      });
      articles = [...articles, ...articleRes.data];
    }
    this.articles = articles;
    this.$stopLoading('article');
  }

  public async getRewardsData(venues: string[], customerGroup: CustomerGroup) {
    const availableCustomerGroup: CustomerGroup = customerGroup || (this.customerGroup as CustomerGroup);

    if (!availableCustomerGroup) {
      return;
    }

    const isPiggyLoyaltyEnabled: boolean = this.venues
      .filter((venue: Venue) => venues.includes(venue._id))
      .every((venue: Venue) => !!venue.piggyLoyaltyEnabled);

    if (isPiggyLoyaltyEnabled) {
      this.setRewardsFilter({ customerGroup: availableCustomerGroup });
      setTimeout(async () => {
        await this.getRewards();
      }, 0);
    } else {
      this.resetRewards();
    }
  }

  public save() {
    this.$refs.general.validate().then(async (res: boolean | boolean[]) => {
      if (!this.isValid(res)) {
        this.notifyError('notification.form');
        return;
      }

      try {
        this.$startLoading('promo.save');
        const data: Partial<PromoCode> = { ...this.getMergedData() };
        let response;

        if (this.editing) {
          await this.update({ ...data, id: this.active!._id });
          await this.handleUploadCsv();
        } else {
          if (this.multiple) {
            const formData: FormData = new FormData();

            formDataAppendObject(formData, data, 'code');
            formData.append('amount', `${data.generateAmount || 10}`);

            response = await this.generateCodes(formData);
          } else {
            if (this.teedeli) {
              response = await this.storeTeedeliCode({ ...this.getMergedTeedeliData() });
            } else {
              response = await this.store({ ...this.getMergedData() });
              await this.handleUploadCsv();
            }
          }
        }

        if (!this.multiple && data.image && data.image instanceof File) {
          data.id = this.editing ? this.active!._id : response?._id || response?.id;

          if (data?.id) {
            const formData: FormData = new FormData();
            formData.append('asset', data.image);

            await this.uploadImage({ ...data, image: formData });
          }
        }

        this.$router.push({ name: 'promo.index' });
      } finally {
        this.$stopLoading('promo.save');
      }
    });
  }

  protected getMergedData(): Partial<PromoCode> {
    return {
      ...this.$refs.general.getData(),
      ...this.$refs.image.getData(),
      ...this.$refs.availableHours.getData(),
      availability: {
        ...this.$refs.availability.getData(),
      },
    };
  }

  protected getMergedTeedeliData(): Partial<PromoCodeTeedeli> {
    return {
      amount: this.$refs.general.getData().amountPromo,
      code: {
        ...this.$refs.general.getData(),
        availability: {
          ...this.$refs.availability.getData(),
        },
      },
    };
  }

  private async handleUploadCsv(): Promise<void> {
    const formData: FormData = new FormData();
    const uploadedCsv = this.$refs.general.getData().uploadedGiftCards;
    const needToUpload = uploadedCsv && uploadedCsv instanceof File;

    if (needToUpload && uploadedCsv) {
      formData.set('file', uploadedCsv);
      await this.uploadGiftCards({ id: this.active!._id, file: formData });
    }
  }
}
